@import "../App.css";

.contact-container{
    /*margin:3rem 0rem;*/
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height:15vh;
    width: 20vw ;
    color:white;
    background-color:#007FFF;
    border-radius: 5px;
    border: solid black 3px;
    padding:1rem;
    margin:1rem 0rem;
    -webkit-box-shadow: 7px -6px 0px -1px black; 
    box-shadow: 7px -6px 0px -1px black;
}

.contact-container:hover{
    cursor: pointer;
}
#calendly-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width:max-content;
    margin: 1rem 0rem;
}

#calendly-button:hover{
    cursor: pointer;
}

.gen-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:100vh;
    width:100vw;
    position:relative;
}

.contact-item-container a{
    text-decoration: none;
    color: white;
    
}

.portfolio:hover{
    background-color: white;
    color:#007FFF !important;
}
#popup-container{
    width:20vw;
}

@media (max-width: 768px) {
    .contact-container{
        width: 80vw;
    }
    #popup-container{
        width:100%;
    }
    #calendly-button{
        width:80vw;
    }
}