@import "../App.css";


.home-container{
    position:relative;
    width:100vw;
    height:100vh;
    box-sizing: border-box;
}

.contact-link:hover{
    background-color: white;
    color: var(--blue);
    font-weight: 800;
}